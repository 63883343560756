



import Vue from 'vue';
export default Vue.extend({
  name: "accounts-home-view",
  data() {
    return {};
  },
  computed: {},
  metaInfo(): any {
    return {
      title: 'Funimation',
      meta: [
        {
          vmid: 'og:title',
          name: 'og:title',
          content: 'Page title text example',
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Short synopsis example text',
        },
        {
          vmid: 'og:description',
          name: 'og:description',
          content: 'Description Example',
        },
        {
          vmid: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'Image url gloes here',
        },
      ],
    };
  },
});
